import React, { useState, useEffect } from 'react'
import { parse } from 'node-html-parser';
import PropTypes from 'prop-types'
import { withTranslation } from 'i18n'
import css from 'styled-jsx/css'
import Head from 'next/head'
import find from 'lodash/find'
import { ChakraProvider, Box, extendTheme, theme as defaultChakraTheme } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import Tooltip from 'ui/dist/admin/tooltip'
import fontFamilies from 'configs/font-families'
import ConditionalWrapper from 'lib/conditional-wrapper'
import NavigationsSection from 'containers/hub-page/views/sections/navigations'
import BannerSection from 'containers/hub-page/views/sections/banner'
import ProjectsSection from 'containers/hub-page/views/sections/projects'
import FeaturedSection from 'containers/hub-page/views/sections/featured'
import FooterSection from 'containers/hub-page/views/sections/footer'
import CustomSection from 'containers/hub-page/views/sections/custom'
import ImportantLinksSection from 'containers/hub-page/views/sections/important-links'
import KeyDatesSection from 'containers/hub-page/views/sections/key-dates'
import DocumentsSection from 'containers/hub-page/views/sections/documents'
import LifeCycleSection from 'containers/hub-page/views/sections/life-cycle'
import FAQSection from 'containers/hub-page/views/sections/faq'
import RegisterWidgetSection from 'containers/hub-page/views/sections/register-widget'
import CarouselSection from 'containers/hub-page/views/sections/carousel'
import WhosListeningSection from 'containers/hub-page/views/sections/whos-listening'
import TitleSection from 'containers/hub-page/views/sections/title'
import TitleWithDescriptionSection from 'containers/hub-page/views/sections/title-with-description'
import ProjectFinderSection from 'containers/hub-page/views/sections/project-finder'
import VideoSection from 'containers/hub-page/views/sections/video'
import HtmlSection from 'containers/hub-page/views/sections/html'
import CookiePolicy from 'containers/cookie-policy'
import CookieConsentModal from 'components/cookie-consent-modal'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash';

const { className, styles } = css.resolve`
  .section-container.admin-view {
    position: relative;
    border: 1px dashed transparent;
  }

  .section-container.admin-view:hover {
    cursor: pointer;
    border-color: #0e4b70;
  }

  .section-settings-edit-button-container {
    display: none;
    position: relative;
  }

  .section-container:hover .admin-view.section-settings-edit-button-container {
    display: block;
  }

  .section-settings-add-button-container {
    display: block;
    position: relative;
  }

  .no-revision-draft {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background-color: #f7f7f7;
    flex-direction: column;
  }

  .image {
    margin-bottom: 50px;
  }

  .text {
    font-size: 25px;
  }

  .hub-page-view-section:hover {
    opacity: 0.6;
  }
`

const HubPageView = ({
  bodyFont,
  headingFont,
  isInsideAdminView,
  setCurrentSectionId,
  showAddNewSectionDrawer,
  changeHubPageSectionKey,
  t,
  brandColor,
  homePageMeta,
  hubPageMeta,
  hubPageSections,
  liquidTemplates,
  isReadSpeakerEnabled,
  isCookiePolicyEnabled,
  isInsideDevicePreview,
  isGoogleAnalyticsEnabled,
  googleAnalyticsAccount,
  customTrackingCode,
  customHeadCode,
  dataTestId,
  isnewCookieConsentManagerEnabled,
  isUserTrackingAnalyticsEnabled,
  isPFEAuthenticationEnabled,
  isPFEIdeasEnabled
}) => {
  const [addSectionButtonOpen, setAddSectionButtonOpen] = useState(null)
  const [positionOfNewSection, setPositionOfNewSection] = useState(null)
  const bodyFontFamily = find(fontFamilies, (f) => f.key === bodyFont) || fontFamilies[0]
  const headingFontFamily = find(fontFamilies, (f) => f.key === headingFont) || fontFamilies[0]
  const lengthOfHubPageSections = hubPageSections.length
  const [isCookieBoxVisible, setCookieBoxVisiblity] = useState(false)

  const setTimeoutScrollList = []
  const setTimeoutClickList = []
  /**
   * !isInsideAdminView - Checks if it is on participant side
   * isCookiePolicyEnabled - Checks if it is enabled by admin
   * isCookieSet - Checks if cookie is already present
   * isInsideDevicePreview - Check if it is from device preview
   */
  const showCookiePolicy = !isInsideAdminView && isCookiePolicyEnabled && isCookieBoxVisible && !isInsideDevicePreview

  const showEnhancedCookieConsentManager = showCookiePolicy && isnewCookieConsentManagerEnabled

  useEffect(() => {
    // Checks if the cookie already exist
    if (isCookiePolicyEnabled) {
      const allCookies = document.cookie.split('; ')
      const cookieConsent = find(allCookies, cookie => cookie.includes('cookie_consent'))

      if (!cookieConsent) {
        setCookieBoxVisiblity(true)
      }

      return function clean () {
        for (let i = 0; i < setTimeoutScrollList.length; i++) {
          clearTimeout(setTimeoutScrollList[i])
        }

        for (let i = 0; i < setTimeoutClickList.length; i++) {
          clearTimeout(setTimeoutClickList[i])
        }
      }
    }
  }, [isCookieBoxVisible])

  useEffect(() => {
    if(isPFEAuthenticationEnabled){
      Cookies.set('participant_fe', 'new', {
        path: '/',
        secure: true,
        sameSite: 'Strict',
      })
    } else {
      Cookies.remove('participant_fe', { path: '/' })
    }
  }, [isPFEAuthenticationEnabled])

  useEffect(() => {
    if (isPFEIdeasEnabled) {
      Cookies.set('pfe_ideas', 'new', {
        path: '/',
        secure: true,
        sameSite: 'Strict',
      });
    } else {
      Cookies.remove('pfe_ideas', { path: '/' });
    }
  }, [isPFEIdeasEnabled]);

  useEffect(() => {
    if (!isInsideAdminView && !isInsideDevicePreview) {
      loadCustomHeadCode()
    }
  }, [])

  const theme = {
    'breakpoints': ['30em', '48em', '62em', '80em'],
    'zIndices': {
      ...defaultChakraTheme.zIndices
    },
    'radii': {
      ...defaultChakraTheme.radii,
      'site': (homePageMeta && homePageMeta.borderRadius) || '0.125em'
    },
    'opacity': {
      ...defaultChakraTheme.opacity
    },
    'borders': {
      ...defaultChakraTheme.borders
    },
    'colors': {
      'brand': brandColor,
      ...defaultChakraTheme.colors
    },
    'letterSpacings': {
      ...defaultChakraTheme.letterSpacings
    },
    'lineHeights': {
      ...defaultChakraTheme.lineHeights
    },
    'fontWeights': {
      ...defaultChakraTheme.fontWeights,
      'body': 400,
      'heading': 700
    },
    'fonts': {
      heading: `${headingFontFamily.name}, Helvetica, Arial, sans-serif`,
      body: `${bodyFontFamily.name}, Helvetica, Arial, sans-serif`
    },
    fontSizes: {
      ...defaultChakraTheme.fontSizes,
      heading: `${(hubPageMeta && hubPageMeta.headingFontSize) || '1.5'}em`,
      sectionTitle: `${(hubPageMeta && hubPageMeta.sectionTitleFontSize) || '2'}em`,
      body: `${(hubPageMeta && hubPageMeta.baseFontSize) || '1'}em`
    },
    'sizes': {
      ...defaultChakraTheme.sizes
    },
    'shadows': {
      ...defaultChakraTheme.shadows,
      'site': (homePageMeta && homePageMeta.boxShadow) || '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
    },
    'space': {
      ...defaultChakraTheme.space
    },
    'icons': {
      ...defaultChakraTheme.icons
    }
  }

  const createMarkupForLiquidTemplate = (html) => {
    return { __html: html }
  }

  const mapSectionTypeToView = (type, index) => {
    switch (type) {
      case 'projectFinder':
        return (
          <ProjectFinderSection
            sectionId={index}
            isInsideAdminView={isInsideAdminView}
          />
        )

      case 'whosListening':
        return <WhosListeningSection sectionId={index} />

      case 'title':
        return <TitleSection sectionId={index} />

      case 'carousel':
        return (
          <CarouselSection
            sectionId={index}
            isInsideAdminView={isInsideAdminView}
          />
        )

      case 'custom':
        return <CustomSection sectionId={index} />

      case 'footer':
        return <FooterSection sectionId={index} />

      case 'navigation':
        return (
          <NavigationsSection
            sectionId={index}
            isInsideAdminView={isInsideAdminView}
          />
        )

      case 'banner':
        return (
          <BannerSection
            sectionId={index}
          />
        )

      case 'projects':
        return (
          <ProjectsSection
            sectionId={index}
            isInsideAdminView={isInsideAdminView}
          />
        )

      case 'featured':
        return <FeaturedSection sectionId={index} />

      case 'registerWidget':
        return <RegisterWidgetSection sectionId={index} />

      case 'importantLinks':
        return <ImportantLinksSection sectionId={index} />

      case 'keyDates':
        return <KeyDatesSection sectionId={index} />

      case 'documents':
        return <DocumentsSection sectionId={index} />

      case 'lifeCycle':
        return <LifeCycleSection sectionId={index} />

      case 'faq':
        return <FAQSection sectionId={index} />

      case 'titleWithDescription':
        return <TitleWithDescriptionSection sectionId={index} />

      case 'video':
        return <VideoSection sectionId={index} />

      case 'html':
        return <HtmlSection sectionId={index} />

      default:
        return (
          <ProjectsSection
            sectionId={index}
            isInsideAdminView={isInsideAdminView}
          />
        )
    }
  }

  const handleScrollIntoView = index => {
    changeHubPageSectionKey('sections')

    let scrollTimeout = setTimeout(function () {
      if (document.getElementById(`hub-page-editor-section-${index}`)) {
        document.getElementById(`hub-page-editor-section-${index}`).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      }
    }, 1000)

    setTimeoutScrollList.push(scrollTimeout)
  }

  if (!lengthOfHubPageSections) {
    const UnderConstructionIllustration = dynamic(() => import(/* webpackChunkName: 'UnderConstructionIllustration' */ 'static/assets/images/under-construction.png'))

    return (
      <div className={`${className} no-revision-draft`}>
        <div className={`${className} image`}>
          <img
            src={UnderConstructionIllustration}
            alt={t('editor:editor.pageUnderConstruction')}
            height='400'
          />
        </div>
        <div className={`${className} text`}>
          {t('editor:editor.pageUnderConstruction')}
        </div>
        {styles}
      </div>
    )
  }

  const unEditableSections = ['navigation', 'footer']

  const sectionNode = (section, index) => {
    if (isInsideAdminView) {
      const EditSectionButton = dynamic(() => import(/* webpackChunkName: 'EditSectionButton' */ 'containers/hub-page/editor/buttons/edit-section-button'))
      const AddNewSectionButton = dynamic(() => import(/* webpackChunkName: 'AddNewSectionButton' */ 'components/hub-page/editor/buttons/add-new-section-button'))

      if (unEditableSections.indexOf(section.meta.type) > -1) {
        return (
          <div
            key={index}
            role='button'
            className={`${className} section-container ${isInsideAdminView ? 'admin-view' : ''}`}
          >
            <section
              className={`${className} hub-page-view-section`}
              id={`hub-page-view-section-${index}`}
            >
              {mapSectionTypeToView(section.meta.type, index)}
            </section>
            {section.meta.type !== 'footer' &&
              <div className={`${className} section-settings-add-button-container ${isInsideAdminView && 'admin-view'}`}>
                <AddNewSectionButton
                  sectionId={index}
                  hoveredSection={addSectionButtonOpen}
                  onClick={() => {
                    setPositionOfNewSection(index + 1)
                    showAddNewSectionDrawer(true)
                  }}
                  dataTestId={`${dataTestId}-add-noneditable-section`}
                />
              </div>
            }
          </div>
        )
      }

      return (
        <div
          key={index}
          onMouseEnter={() => setAddSectionButtonOpen(index)}
          onMouseLeave={() => setAddSectionButtonOpen(null)}
          className={`${className} section-container ${isInsideAdminView ? 'admin-view' : ''}`}
        >
          <div className={`${className} section-settings-edit-button-container ${isInsideAdminView && 'admin-view'}`}>
            <EditSectionButton sectionId={index} />
          </div>

          <section
            onClick={() => {
              if (isInsideAdminView) {
                setCurrentSectionId(index)

                let clickTimeout = setTimeout(() => {
                  handleScrollIntoView(index)
                }, 1000)

                setTimeoutClickList.push(clickTimeout)
              }
            }}
            className={`${className} hub-page-view-section`}
            id={`hub-page-view-section-${index}`}
          >
            {mapSectionTypeToView(section.meta.type, index)}
          </section>
          {section.meta.type !== 'footer' &&
            <div className={`${className} section-settings-add-button-container ${isInsideAdminView && 'admin-view'}`}>
              <AddNewSectionButton
                sectionId={index}
                hoveredSection={addSectionButtonOpen}
                onClick={() => {
                  setPositionOfNewSection(index + 1)
                  showAddNewSectionDrawer(true)
                }}
                dataTestId={`${dataTestId}-add-editable-section`}
              />
            </div>
          }
        </div>
      )
    } else {
      return (
        <section key={index}>
          {mapSectionTypeToView(section.meta.type, index)}
        </section>
      )
    }
  }

  const fetchFontFamilies = () => {
    return (
      <Head>
        <link href={bodyFontFamily.src} rel='stylesheet' />
        <link href={headingFontFamily.src} rel='stylesheet' />
      </Head>
    )
  }


  const handleEnhancedGoogleAnalyticsScript = () => {
    return(
      <Head>
      <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsAccount}`}
      />
     <script>
        {
           `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('consent', 'default', {'analytics_storage': 'denied'})
           gtag('js', new Date());
           gtag('config', '${googleAnalyticsAccount}');
           `
        }
        </script>
    </>
    </Head>
    )
  }

  const handleGoogleAnalyticsScript = () => {
    return(
      <Head>
      <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsAccount}`}
      />
      <script>
        { `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleAnalyticsAccount}');
          `
        }
    </script>
    </>
    </Head>
    )
  }



  /**
   * When new cookie consent manager is enabled 
   * deny the access to cookie storage for google 
   * analytics when the user loads the application for the 
   * first time.
   */
  const fetchGoogleAnalytics = () => {
    if(isGoogleAnalyticsEnabled && !isInsideAdminView){
      if(isnewCookieConsentManagerEnabled){
        return handleEnhancedGoogleAnalyticsScript()
      }
      return handleGoogleAnalyticsScript()
    }
  }

  const setCustomBodyCode = () => {
    return (
      <div dangerouslySetInnerHTML={{ __html: customTrackingCode }} />
    )
  }

  const createCommentNode = (comment) => {
    const commentNode = document.createComment(comment)
    document.head.appendChild(commentNode)
  }
  const customMetaTag = () => {
    if (isEmpty(customHeadCode)) return;
    const customCode = parse(customHeadCode);
    const elements = customCode?.querySelectorAll('meta');

    return elements?.map((ele) => ele?.attributes && <meta {...ele.attributes} />);
  };

  const loadCustomHeadCode = () => {
    // eslint-disable-next-line
    const parser = new DOMParser()
    const customCode = parser.parseFromString(customHeadCode, 'text/html')

    customCode.childNodes.forEach(child => {
      // eslint-disable-next-line
      if (child.nodeType === Node.COMMENT_NODE) {
        createCommentNode(child.nodeValue)
      }
    })

    if (customCode.body.innerText !== '') {
      createCommentNode(customHeadCode)
    }

    const elements = customCode.head.getElementsByTagName('*')
    elements.length &&
    (
      elements.forEach(ele => {
        switch (ele.tagName.toUpperCase()) {
          case 'SCRIPT':
            const script = document.createElement('script')
            script.innerHTML += ele.innerHTML
            ele.src && script.setAttribute('src', ele.src)
            document.head.appendChild(script)
            break
          case 'META':
            const meta = document.createElement('meta')
            meta.name = ele.name
            meta.content = ele.content
            document.head.appendChild(meta)
            break
          case 'LINK':
            const link = document.createElement('link')
            link.rel = ele.rel
            link.type = ele.type
            link.href = ele.href
            document.head.appendChild(link)
            break
          case 'STYLE':
            const style = document.createElement('style')
            style.innerHTML += ele.innerHTML
            document.head.appendChild(style)
            break
          case 'BASE':
            const base = document.createElement('base')
            base.href = ele.href
            base.target = ele.target
            document.head.appendChild(base)
            break
          case 'NOSCRIPT':
            const content = parser.parseFromString(customHeadCode, 'text/xml')
            const noscript = document.createElement('noscript')
            noscript.innerHTML += content.getElementsByTagName('noscript')[0].innerHTML
            document.head.appendChild(noscript)
            break
          default:
            break
        }
      })
    )
  }

  const liquidHeaderNode = () => {
    const { body } = find(liquidTemplates, template => template.path === 'liquid_templates/header')

    if (isInsideAdminView) return false

    return (
      <div dangerouslySetInnerHTML={createMarkupForLiquidTemplate(body)} />
    )
  }

  const liquidFooterNode = () => {
    const { body } = find(liquidTemplates, template => template.path === 'liquid_templates/footer')

    if (isInsideAdminView) return false

    return (
      <div dangerouslySetInnerHTML={createMarkupForLiquidTemplate(body)} />
    )
  }

  const addNewSectionDrawerNode = () => {
    if (!isInsideAdminView) return false

    const AddNewSectionDrawer = dynamic(() => import(/* webpackChunkName: 'AddNewSectionDrawer' */ 'containers/hub-page/editor/drawers/add-new-section-drawer'))

    return (
      <AddNewSectionDrawer
        position={positionOfNewSection}
        onClose={() => {
          showAddNewSectionDrawer(false)
          setPositionOfNewSection(null)
        }}
      />
    )
  }

  const isUserOnIE = () => {
    // Checks if the current browser is IE
    const userAgent = typeof window === 'object' && window.navigator.userAgent
    const isIE = /MSIE|Trident/.test(userAgent)

    return isIE
  }

  const header = () => {
    return (
      <Box
        as='header'
        style={{
          zoom: !isUserOnIE() && (isInsideAdminView ? 0.8 : 1)
        }}
      >
        {liquidHeaderNode()}
        {sectionNode(hubPageSections[0], 0)}
      </Box>
    )
  }

  const renderHeader = () => {
    if (isInsideAdminView) {
      return (
        <Tooltip placement='top' title={t('editor:editor.disableMessage')}>
          <Box>
            {header()}
          </Box>
        </Tooltip>)
    }

    return header()
  }

  const footerNode = () => {
    return (
      <Box
        as='footer'
        style={{
          zoom: !isUserOnIE() && (isInsideAdminView ? 0.8 : 1)
        }}
      >
        {sectionNode(hubPageSections[lengthOfHubPageSections - 1], lengthOfHubPageSections - 1)}
        {liquidFooterNode()}
        {!isInsideAdminView && !isInsideDevicePreview && setCustomBodyCode()}
      </Box>
    )
  }

  const renderFooter = () => {
    if (isInsideAdminView) {
      return (
        <Tooltip placement='top' title={t('editor:editor.disableMessage')}>
          <Box>
            {footerNode()}
          </Box>
        </Tooltip>)
    }

    return footerNode()
  }

  return (
    <ChakraProvider theme={extendTheme(theme)} data-testid={`${dataTestId}-theme`}>
       <Head>
        {!isInsideAdminView && !isInsideDevicePreview && customMetaTag()}
      </Head>
      {showCookiePolicy && !isnewCookieConsentManagerEnabled && (
        <CookiePolicy setCookieBoxVisiblity={setCookieBoxVisiblity} />
      )}
      {
        showEnhancedCookieConsentManager && <CookieConsentModal isGoogleAnalyticsEnabled={isGoogleAnalyticsEnabled} isUserTrackingAnalyticsEnabled={isUserTrackingAnalyticsEnabled} brandColor={brandColor}/>
      }
      {fetchFontFamilies()}
      {fetchGoogleAnalytics()}
      {addNewSectionDrawerNode()}
      {renderHeader()}
      <ConditionalWrapper
        condition={!isInsideAdminView && isReadSpeakerEnabled}
        wrap={children => <div id='js-readspeaker-container'>{children}</div>}
      >
        <Box
          as='main'
          fontSize={isInsideAdminView ? 'inherit' : '16px'}
          style={{
            zoom: !isUserOnIE() && (isInsideAdminView ? 0.8 : 1)
          }}
          data-testid={`${dataTestId}-box-container`}
          id='hubpage-main'
        >
          {hubPageSections.slice(1, lengthOfHubPageSections - 1).map((section, index) => {
            if (section.meta.hidden) {
              return false
            }

            return sectionNode(section, index + 1)
          })}
        </Box>
      </ConditionalWrapper>
      {renderFooter()}
      <style jsx>{`
        :global(.admin-view .ant-row-flex-center) {
          width: 100%;
        }

        :global(select::-ms-expand) {
          display: none;
        }

        @media only screen and (min-width: 991px) {
          :global(.ant-row-flex-center) {
            width: 100%;
            margin: 0 !important;
          }
        }

        :global(select) {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: checkbox-container;
        }

        :global(a) {
          color: ${brandColor};
        }
        :global(.confirm-button) {
          background-color: ${brandColor} !important;
        }
        :global(.confirm-button:hover) {
          background-color: ${brandColor} !important;
          filter: brightness(85%);
        }
      `}</style>
      {styles}
    </ChakraProvider>
  )
}

HubPageView.propTypes = {
  bodyFont: PropTypes.string,
  headingFont: PropTypes.string,
  homePageMeta: PropTypes.object,
  hubPageMeta: PropTypes.object,
  hubPageSections: PropTypes.array,
  isInsideAdminView: PropTypes.bool,
  isCookiePolicyEnabled: PropTypes.bool,
  isInsideDevicePreview: PropTypes.bool,
  setCurrentSectionId: PropTypes.func.isRequired,
  showAddNewSectionDrawer: PropTypes.func.isRequired,
  changeHubPageSectionKey: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  brandColor: PropTypes.string,
  liquidTemplates: PropTypes.array,
  isReadSpeakerEnabled: PropTypes.bool.isRequired,
  isGoogleAnalyticsEnabled: PropTypes.bool,
  googleAnalyticsAccount: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  isUserTrackingAnalyticsEnabled: PropTypes.bool,
  isnewCookieConsentManagerEnabled: PropTypes.bool,
  isPFEAuthenticationEnabled: PropTypes.bool
}

export default withTranslation(['common', 'editor', 'navigation'])(HubPageView)
